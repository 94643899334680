<template>
    <v-container style="height: 100%; width: 100%;background: white;">
        <!-- {{ selected }}
        {{ accountsDataTable.items }}
        {{ rawRet._id }}
        {{ filteredItemCount }} -->
        <v-row>
            <v-col cols="12">
                <v-data-table v-model="accountSelected" :loading="accountsDataLoading" item-key="acctID" dense :hide-default-footer="false" :headers="accountsDataTable.headers" :items="accountsDataTable.items" 
                    :items-per-page="5"
                    :footer-props="{
                        'items-per-page-options': [5, 10, 20, 30, 40, 50]
                    }"
                    @click:row="accountsDataTableRowClick" single-select show-select
                    sort-by="acctID"
                    sort-desc
                    class="elevation-1">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>WalletPass Accounts</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-tooltip v-if="isSuperAdmin" top hide-on-click hide-on-hover open-on-hover>
                                    <template v-slot:activator="{ on}">
                                        <v-btn v-on="on" small class="btn-icon mt-1 mr-2" @click="refreshClick()">                        
                                            <v-icon>
                                                refresh
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    Reload
                                </v-tooltip>
                                <v-dialog content-class="addAccountDlg" v-model="dialog" max-width="500px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-show="isSuperAdmin || filteredItemCount==0" v-on="on" v-bind="attrs" small class="btn-icon mt-1 mr-2" @click="addClick()">                        
                                            <v-icon>
                                                add
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card height="337">
                                        <v-card-text>
                                        <v-row class="pt-2">
                                            <v-col cols="6">
                                                <v-text-field :disabled="!isSuperAdmin" clearable hide-details v-model="editedItem.acctID" :label=accountsDataTable.headers[0].value></v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field clearable hide-details v-model="editedItem.parentAcctID" :label=accountsDataTable.headers[1].value></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field clearable hide-details v-model="editedItem.accessKey" :label=accountsDataTable.headers[2].value></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field clearable hide-details v-model="editedItem.secretKey" :label=accountsDataTable.headers[3].value></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field clearable hide-details v-model="editedItem.passKey" :label=accountsDataTable.headers[4].value></v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field clearable hide-details v-model="editedItem.defSuppression" :label=accountsDataTable.headers[5].value></v-text-field>
                                            </v-col>
                                        </v-row>  
                                        </v-card-text>                                      
                                    <v-card-actions>
                                        <v-row>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="dialog=false;">
                                                Cancel
                                            </v-btn>
                                            <v-btn color="blue darken-1" text @click="save">
                                                <span v-if="rawRet._id">Save</span>
                                                <span v-else>Add</span>
                                            </v-btn>
                                        </v-row>                                            
                                        </v-card-actions>
                                    </v-card>                                    
                                </v-dialog>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.updatedAt`]="props">
                            <div>{{ fromNow(props.item.updatedAt)}}</div>
                            <!-- {{props.item.lastModified}} -->
                        </template>
                        <template v-slot:[`item.enable`]="props">
                            <v-switch @click.native.stop class="my-1" v-model="props.item.enable" xcolor="success" hide-details>
                            </v-switch>
                        </template>
                        <template v-slot:[`item.action`]="props">
                           <v-tooltip  top hide-on-click hide-on-hover open-on-hover>
                              <template v-slot:activator="{ on, attrs }">
                                 <v-icon   :disabled="props.item.enable==false"  v-bind="attrs" v-on="on" small class="mr-2" @click="editClick(props)">
                                       mode
                                 </v-icon>
                              </template>
                              <span>Edit</span>
                           </v-tooltip>
                           <v-tooltip v-if="isSuperAdmin" top hide-on-click hide-on-hover open-on-hover>
                              <template v-slot:activator="{ on , attrs}">
                                 <v-icon @click.native.stop v-bind="attrs" v-on="on" small class="mr-2" @click="deleteClick(props)">
                                       delete
                                 </v-icon> 
                              </template>
                              <span>Remove</span>
                           </v-tooltip>
                    </template>                        
                </v-data-table>
            </v-col>                    
        </v-row>
    </v-container>
</template>
<style>
.addAccountDlg {
    overflow-y: auto;
    overflow-x: hidden;
    height: 325px;
}
</style>
<style scoped>
</style>
<script>
import axios from "axios";
//  import utils from '../services/KCUtils.js'
var crypto = require('crypto');
const NAME = "KCWalletPassAdmin";
// const serverMode="pass";
// let config = utils.getServerConfig(serverMode);
// import csvUtils from "../services/KCCSVUtils.js";

export default {
    name: NAME,
    components: {
    },
    props: {
        passUIEndpoint: {
          type: String,
          //default: "https://8mdmei6e83.execute-api.us-west-1.amazonaws.com/test/api/csvprocessor?cmd=getDocument&name=walletPassConfig",
          default: process.env.VUE_APP_PASSUI_ENDPOINT?process.env.VUE_APP_PASSUI_ENDPOINT:"https://kiws7ng8z2.execute-api.us-west-1.amazonaws.com/stage/passui/process"
        },
        isSuperAdmin: {
            type: Boolean,
            default: false
        }    
    },
    data() {
        return {
            davinciUrl: null,
            accountSelected: [],
            dialog: false,
            accountsDataLoading: false,
            accountsDataTable: {
                headers: [
                    // { text: "ID", value: "id", sortable: true },
                    { text: "accountID", value: "acctID", sortable: false, filter: value=>{
                        if (this.isSuperAdmin) return true;
                        return value == this.account;
                    }},
                    { text: "parentID", value: "parentAcctID", sortable: false, /*width: '150px'*/ },
                    // { text: "Enable", value: "enable", sortable: false },                    
                    { text: "accessKey", value: "accessKey", sortable: false},
                    { text: "secretKey", value: "secretKey", sortable: false},
                    { text: "passKey", value: "passKey", sortable: false},
                    { text: "suppression", value: "defSuppression", sortable: false},
                    { text: "action", value: "action", sortable: false,cellClass:'action-class', align: 'center', },
                ],
                items: []
            },
            default_editedItem: {
                acctID: "",
                accessKey: "",
                parentAcctID: "",
                secretKey: "",
                passKey: "",
                defSuppression: 360
            },
            editedItem: {... this.default_editedItem},
            rawRet: {},
        };
    },
    computed: {
        account: function () {
            //console.log('user object: ', this.$store.getters.user);
            return this.$store.getters.user.account;
        },
        filteredItemCount() {
            // Assuming you have access to the original data source.
            // Replace 'dataSource' with your actual data source.
            let filteredItems = this.accountsDataTable.items.filter(item => {
                // Apply your filter logic here
                if (this.isSuperAdmin) return true;
                return item.acctID == this.account;
            });
            return filteredItems.length;
        }
    },
    methods: {
        async loadData(){
            this.accountsDataLoading = true;
            var ret = await axios.get(this.passUIEndpoint+`?cmd=getMaster&stage=${this.server}`);
            console.log("loadData", ret.data);
            this.accountsDataTable.items = ret.data.result.definition.data;
            // merge new fields
            console.log("editedItem", JSON.stringify(this.default_editedItem));
            for(let x in this.accountsDataTable.items){
                this.accountsDataTable.items[x] = {...this.default_editedItem, ...this.accountsDataTable.items[x]};
            }
            this.accountsDataLoading = false;
            this.rawRet = ret.data.result;
            //return ret.data.result;
        },
        async refreshClick(){
            this.loadData();
        },
        async syncToDB(){
            // persist it
            var postRet = await axios.post(this.passUIEndpoint+`?cmd=saveMaster&stage=${this.server}`,{
                _id: this.rawRet._id,
                data: this.accountsDataTable.items,
            });
            console.log(postRet);
            this.loadData();
        },
        async save(){
            //check if acctID = what we have
            let update = false;
            for(let x in this.accountsDataTable.items){
                if(this.accountsDataTable.items[x].acctID ==  this.editedItem.acctID){
                    this.accountsDataTable.items[x] = {... this.editedItem}
                    update = true;
                    break;
                }
            }
            if(update == false){
                this.accountsDataTable.items.push({... this.editedItem});
            }
            this.dialog = false;
            this.syncToDB();
        },
        accountsDataTableRowClick(param){
            console.log("accountsDataTableRowClick",param);
            this.accountSelected = [param];
        },
        addClick(){
            this.editedItem = {... this.default_editedItem};
            if (!this.isSuperAdmin) this.editedItem.acctID = this.account;
        },
        editClick(props){
            this.editedItem = {... props.item};
            this.dialog = true;
        },
        deleteClick(props){
            console.log("delete",props);
            this.accountsDataTable.items = this.accountsDataTable.items.filter(item => item != props.item);
            this.syncToDB();
        }

    },
    created() {
        this.davinciUrl = this.$store.getters.deployment.dvURL;
        // alert(`in created(): davinciUrl=${this.davinciUrl}`);
        if (this.davinciUrl.indexOf("davincistage") >= 0)
            this.server = "stage";
        else
            this.server = "prod";
        // this.endPoint = config.endPoints.mindfireOne + `/api/card/${this.server}`;
    },
    destroyed() {
    },
    async mounted() {
        this.loadData();
    },
    beforeUnmount() { 
    },
    watch: {
        account: {
            async handler(val) {
            },
            deep: true
        },
    },
};
</script>